export const HOVERING_BEHAVIOUR = {
  APPEARS: 'APPEARS',
  NEVER_SHOW: 'NEVER_SHOW',
  NO_CHANGE: 'NO_CHANGE',
  DISAPPEARS: 'DISAPPEARS',
} as const;

export const OPTION_GALLERY_SIZE_TYPE = {
  RATIO: 'ratio',
  PX: 'px',
  SMART: 'smart',
} as const;

export const OVERLAY_ANIMATION = {
  NO_EFFECT: 'NO_EFFECT',
  FADE_IN: 'FADE_IN',
  EXPAND: 'EXPAND',
  SLIDE_UP: 'SLIDE_UP',
  SLIDE_RIGHT: 'SLIDE_RIGHT',
} as const;

export const IMAGE_HOVER_ANIMATION = {
  NO_EFFECT: 'NO_EFFECT',
  ZOOM_IN: 'ZOOM_IN',
  BLUR: 'BLUR',
  GRAYSCALE: 'GRAYSCALE',
  SHRINK: 'SHRINK',
  INVERT: 'INVERT',
  COLOR_IN: 'COLOR_IN',
  DARKENED: 'DARKENED',
} as const;

export const IMAGE_LOADING_MODE = {
  BLUR: 'BLUR',
  COLOR: 'COLOR',
  MAIN_COLOR: 'MAIN_COLOR',
} as const;

export const CUBE_TYPE = {
  FILL: 'fill',
  FIT: 'fit',
  MIN: 'min',
  MAX: 'max',
} as const;

export const TEXT_PLACEMENT = {
  SHOW_ON_HOVER: 'SHOW_ON_HOVER',
  SHOW_BELOW: 'SHOW_BELOW',
  SHOW_ABOVE: 'SHOW_ABOVE',
  SHOW_ON_THE_RIGHT: 'SHOW_ON_THE_RIGHT',
  SHOW_ON_THE_LEFT: 'SHOW_ON_THE_LEFT',
} as const;

export type TextPlacement =
  (typeof TEXT_PLACEMENT)[keyof typeof TEXT_PLACEMENT];

export const CALCULATE_TEXT_BOX_HEIGHT = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
} as const;

export const TEXT_BOX_WIDTH_CALCULATION_OPTIONS = {
  MANUAL: 'MANUAL',
  PERCENT: 'PERCENT',
} as const;

export const TEXT_BOX_ALIGNMENT_MAP = [
  null,
  TEXT_PLACEMENT.SHOW_ON_THE_LEFT,
  TEXT_PLACEMENT.SHOW_ON_THE_RIGHT,
] as const;

export type TextBoxAlignment = (typeof TEXT_BOX_ALIGNMENT_MAP)[number];

export const LAYOUT_IMAGE_RATIO_MAP = [
  null,
  (16 / 9) as 1.7777777777777777,
  (4 / 3) as 1.3333333333333333,
  1,
  (3 / 4) as 0.75,
  (9 / 16) as 0.5625,
  (16 / 7) as 2.2857142857142856,
] as const;

export type LayoutImageRatio = (typeof LAYOUT_IMAGE_RATIO_MAP)[number];
