import { useMemo } from 'react';
import classNames from 'classnames';
import {
  isLayoutTextOnImage,
  type Layout,
  type MixedLayoutTypeName,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getUseMobileLayoutSettings } from '../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../services/layout-config';
import useIsFeedDesignEnabled from './use-is-feed-design-enabled';
import useLayoutProps from './use-layout-props';
import styles from '../styles/post-list-item.scss';

type UseLayoutColorClassesParams = {
  iconColorClassName?: string;
  textColorClassName?: string;
  separatorBackgroundColorClassName?: string;
};

const useLayoutColorClasses = ({
  iconColorClassName,
  textColorClassName,
  separatorBackgroundColorClassName,
}: UseLayoutColorClassesParams = {}) => {
  const layoutProps = useLayoutProps({ fallbackEnabled: false });
  const feedDesign = useIsFeedDesignEnabled(layoutProps.section);

  const currentLayoutName = useSelector(getUseMobileLayoutSettings)
    ? layoutProps.mobileLayoutName
    : layoutProps.layoutName;

  const layoutClasses = useMemo(() => {
    return feedDesign.applyFeedDesign
      ? layoutColorClasses(
          feedDesign.getPostClassName,
          getFeedColorClassName,
          currentLayoutName,
        )
      : layoutColorClassesBeforeMigration(
          layoutProps.layoutType,
          iconColorClassName,
          textColorClassName,
          separatorBackgroundColorClassName,
        );
  }, [
    currentLayoutName,
    feedDesign.applyFeedDesign,
    feedDesign.getPostClassName,
    layoutProps.layoutType,
    iconColorClassName,
    separatorBackgroundColorClassName,
    textColorClassName,
  ]);

  return layoutClasses;
};

const layoutColorClasses = (
  getPostClassName: (...classNames: string[]) => string[],
  getFeedColorClassNameFn: (
    layoutName: MixedLayoutTypeName,
    colorType: string,
  ) => string,
  layoutName: MixedLayoutTypeName,
) => ({
  iconColorClassName: classNames(
    getPostClassName(getFeedColorClassNameFn(layoutName, 'description-fill')),
  ),
  textColorClassName: classNames(
    getPostClassName(
      getFeedColorClassNameFn(layoutName, 'description-color'),
      getFeedColorClassNameFn(layoutName, 'description-fill'),
      'description-font',
    ),
  ),
  separatorBackgroundColorClassName: classNames(
    getPostClassName(
      getFeedColorClassNameFn(layoutName, 'description-background-color'),
    ),
  ),
});

const layoutColorClassesBeforeMigration = (
  layoutType: Layout | undefined,
  iconColorClassName: string | undefined,
  textColorClassName: string | undefined,
  separatorBackgroundColorClassName: string | undefined,
) =>
  isLayoutTextOnImage(layoutType)
    ? {
        iconColorClassName: styles.textOnImageIcons,
        textColorClassName: styles.textOnImageTextColor,
        separatorBackgroundColorClassName:
          styles.textOnImageSeperatorBackgroundColor,
      }
    : {
        iconColorClassName: iconColorClassName || 'blog-icon-fill',
        textColorClassName: textColorClassName || 'blog-text-color',
        separatorBackgroundColorClassName:
          separatorBackgroundColorClassName ||
          'blog-separator-background-color',
      };

export default useLayoutColorClasses;
