import { GALLERY_CONSTS } from 'pro-gallery';
import type { GalleryProps } from 'pro-gallery-lib';
import {
  isEditor,
  isPreview,
  isSeo,
} from '../store/basic-params/basic-params-selectors';
import type { AppState } from '../types';

export const getProGalleryViewMode = (
  state: AppState,
  rootWidth: number | string | undefined,
): GalleryProps['viewMode'] => {
  if (isEditor(state)) {
    return GALLERY_CONSTS.viewMode.EDIT;
  }

  if (isPreview(state)) {
    return GALLERY_CONSTS.viewMode.PREVIEW;
  }

  if (isSeo(state)) {
    return GALLERY_CONSTS.viewMode.SEO;
  }

  if (!rootWidth) {
    return undefined;
  }

  return GALLERY_CONSTS.viewMode.SITE;
};
