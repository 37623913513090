import type { GalleryProps } from 'pro-gallery-lib';
import {
  PgLayoutImageCropTypeOptions,
  PgLayoutImageResizingModeOptions,
  isLayoutPGSideBySide,
  Layout,
  PgLayoutCardsRowTypeOptions,
  type VideoSettings,
} from '@wix/communities-blog-client-common';
import {
  HOVERING_BEHAVIOUR,
  OPTION_GALLERY_SIZE_TYPE,
  OVERLAY_ANIMATION,
  IMAGE_HOVER_ANIMATION,
  IMAGE_LOADING_MODE,
  CUBE_TYPE,
  TEXT_PLACEMENT,
  CALCULATE_TEXT_BOX_HEIGHT,
  TEXT_BOX_WIDTH_CALCULATION_OPTIONS,
  type LayoutImageRatio,
  type TextBoxAlignment,
} from '../../constants/pro-gallery-options';
import type { Color } from '../../types';

export type ProGalleryOptions = {
  containerWidth: number;
  isRTL: boolean;
  layoutAutoHeightEnabled: boolean;
  layoutCardsRowType: PgLayoutCardsRowTypeOptions;
  layoutContentHeight: number;
  layoutImageCropType: PgLayoutImageCropTypeOptions;
  layoutImageProportions: number;
  layoutImageRatio: LayoutImageRatio;
  layoutImageResizingMode: PgLayoutImageResizingModeOptions | undefined;
  layoutImageWidth: number;
  layoutPostSize: number;
  layoutPostsPerRow: number;
  layoutSpacing: number;
  textBoxAlignment: TextBoxAlignment;
  videoSettings: VideoSettings;

  hideCoverImage?: boolean;
  arrowsSize?: number;
  showArrows?: boolean;
  arrowsPosition?: number;
  arrowsColor?: string | Color;
  autoSlide?: boolean;
  pauseTime?: number;
  loop?: boolean;
};

export const getLayoutImageRatio = (
  layout: Layout | undefined,
  options: ProGalleryOptions,
  cropType: PgLayoutImageCropTypeOptions,
) => {
  if (options.hideCoverImage && options.layoutAutoHeightEnabled) {
    return 99;
  }
  if (`${cropType}` !== PgLayoutImageCropTypeOptions.Max) {
    return options.layoutImageRatio;
  }

  if (isLayoutPGSideBySide(layout)) {
    return 4 / 3;
  }

  return 16 / 9;
};

type ItemBorder = {
  width: number;
  color?: string;
};

type ProGalleryPropOptions = NonNullable<GalleryProps['options']>;

export const createOptions = (
  layout: Layout | undefined,
  options: ProGalleryOptions,
  itemBorder: ItemBorder,
): ProGalleryPropOptions => {
  const galleryWidth = options.containerWidth - itemBorder.width;
  const imageMargin = -(galleryWidth > 0
    ? itemBorder.width
    : itemBorder.width - 1 + galleryWidth);

  const commonGalleryOptions = {
    gallerySizeType: OPTION_GALLERY_SIZE_TYPE.PX,
    gallerySizePx: options.layoutPostSize,
    imageMargin:
      options.layoutSpacing === 0 ? imageMargin : options.layoutSpacing,
    enableInfiniteScroll: true,
    allowTitle: true,
    allowContextMenu: true,
    overlayAnimation: OVERLAY_ANIMATION.FADE_IN,
    imageHoverAnimation: IMAGE_HOVER_ANIMATION.NO_EFFECT,
    imageLoadingMode: IMAGE_LOADING_MODE.BLUR,
    imageInfoType: 'ATTACHED_BACKGROUND',
    textsHorizontalPadding: -30,
    itemBorderWidth: itemBorder.width,
    itemBorderColor: itemBorder.color,
    isRTL: options.isRTL,
    videoPlay: options.videoSettings.play,
    itemClick: 'nothing',
    videoSound: options.videoSettings.sound,
    showVideoPlayButton: options.videoSettings.showPlayButton,
    videoLoop: options.videoSettings.loop,
    videoSpeed: options.videoSettings.playbackSpeed,
    groupTypes: 1,
  };
  const cardsRowOptions =
    options.layoutCardsRowType === PgLayoutCardsRowTypeOptions.ItemsPerRow &&
    options.layoutPostsPerRow
      ? {
          gridStyle: 1,
          numberOfImagesPerRow: options.layoutPostsPerRow,
        }
      : {};
  const cubeType =
    `${options.layoutImageCropType}` === PgLayoutImageCropTypeOptions.Max
      ? CUBE_TYPE.MAX
      : CUBE_TYPE.FILL;
  const cubeRatio = getLayoutImageRatio(
    layout,
    options,
    options.layoutImageCropType,
  );

  const textBoxOptions =
    options.layoutImageResizingMode === PgLayoutImageResizingModeOptions.Fixed
      ? {
          textBoxWidth: options.layoutPostSize - options.layoutImageWidth,
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.MANUAL,
        }
      : {
          textBoxWidthPercent: Math.abs(options.layoutImageProportions - 100),
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        };

  switch (layout) {
    case Layout.PgTextOnImageSmall:
    case Layout.PgTextOnImageMedium:
    case Layout.PgTextOnImageLarge:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        cubeImages: true,
        cubeType: CUBE_TYPE.FILL,
        cubeRatio,
        titlePlacement: TEXT_PLACEMENT.SHOW_ON_HOVER,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NO_CHANGE,
      };
    case Layout.PgSideBySide:
    case Layout.PgSideBySideRight:
      return {
        ...commonGalleryOptions,
        ...textBoxOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: options.textBoxAlignment,
        gridStyle: 1,
        numberOfImagesPerRow: 1,
      };
    case Layout.PgCardMedium:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 1,
        isVertical: true,
        cubeImages: true,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case Layout.PgOneColumn:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        cubeType: CUBE_TYPE.MIN,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case Layout.Slider:
      return {
        ...commonGalleryOptions,
        galleryLayout: 5,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        showArrows: options.showArrows,
        slideshowInfoSize: options.layoutContentHeight,
        arrowsSize: options.arrowsSize,
        isAutoSlideshow: options.autoSlide,
        autoSlideshowInterval: options.pauseTime,
        autoSlideshowType: 'interval',
        arrowsPosition: options.arrowsPosition,
        arrowsColor:
          typeof options.arrowsColor === 'string'
            ? options.arrowsColor
            : options.arrowsColor?.value,
        slideshowLoop: options.loop,
        layoutParams: {
          navigationArrows: {
            verticalAlignment: 'IMAGE_CENTER',
          },
        },
      };
    case Layout.List:
    case Layout.ListMedium:
    case Layout.ListLarge:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        ...textBoxOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        numberOfImagesPerRow: 1,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: `SHOW_BELOW,${options.textBoxAlignment}`,
        textBoxHeight: options.layoutContentHeight,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
      };
    case Layout.PgGrid:
    case Layout.PgGridIntermediate:
    case Layout.PgGridLarge:
    default:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
  }
};

// link to pro-gallery playground: https://pro-gallery.surge.sh
