import { get } from 'lodash';
import {
  VIEW_MODE_SITE,
  VIEW_MODE_EDITOR,
  VIEW_MODE_PREVIEW,
  VIEW_MODE_ONBOARDING,
} from '@wix/communities-blog-client-common';

export const getBasicParams = (state) => state.basicParams || {};

export const getViewMode = (state) => getBasicParams(state).viewMode;
const getFormFactor = (state) => getBasicParams(state).formFactor;
export const getInstance = (state) => getBasicParams(state).instance;
/** Same as using `useEnvironment().language` */
export const getLanguage = (state) => getBasicParams(state).language || 'en';
export const getUrl = (state) => getBasicParams(state).url;
/** Same as using `useEnvironment().isSSR` */
export const isSSR = (state) => getBasicParams(state).isSSR;
/** Same as using `useEnvironment().isRTL` */
export const getIsRTL = (state) => getBasicParams(state).isRTL;
export const isDebug = (state) => getBasicParams(state).isDebug;
export const isProduction = (state) => getBasicParams(state).isProduction;
export const getCommentId = (state) => getBasicParams(state).commentId;
export const getIsRendered = (state) => getBasicParams(state).isRendered;

/** Same as using `useEnvironment().isViewer` */
export const isSite = (state) => getViewMode(state) === VIEW_MODE_SITE;
/** Same as using `useEnvironment().isEditor` */
export const isEditor = (state) => getViewMode(state) === VIEW_MODE_EDITOR;
/** Same as using `useEnvironment().isPreview` */
export const isPreview = (state) => getViewMode(state) === VIEW_MODE_PREVIEW;
export const getIsEditorSegment = (state) =>
  isEditor(state) || isPreview(state);
export const isAdi = (state) => getViewMode(state) === VIEW_MODE_ONBOARDING;
export const isSeo = (state) => getBasicParams(state).isSeo;
/**
 * Not same as using `useEnvironment().isMobile`. Always `false` for non-mobile-friendly sites.
 * @see `render-not-mobile-friendly.spec.ts`
 **/
export const getIsMobile = (state) => getFormFactor(state) === 'Mobile';
export const getIsDesktop = (state) => getFormFactor(state) !== 'Mobile';
export const getIsForumLoadedOnInitialPage = (state) => {
  const biPageNumber = getBasicParams(state).biPageNumber;
  return biPageNumber !== undefined && biPageNumber === 1;
};

const getCurrentSitePage = (state) => get(getBasicParams(state), 'currentPage');
export const getCurrentSitePageTitle = (state) =>
  get(getCurrentSitePage(state), 'name', '');

export const getTimezone = (state) => get(getBasicParams(state), 'timeZone');
