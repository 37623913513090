import React from 'react';
import classNames from 'classnames';
import { resolveId } from '@wix/communities-blog-client-common';
import { type LayoutConfigItemComponentProps } from '@app/external/feed-page/components/post-list/layout-config-component-props';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import { LikeButtonWithCount } from '../like-button-with-count';
import { PostStats } from '../post-stats';
import styles from './post-footer.scss';

type Props = Pick<LayoutConfigItemComponentProps, 'post'> & {
  displayIcons?: boolean;
  className?: string;
  type?: LayoutConfigItemComponentProps['type'] | 'search';
};

export const PostFooter = ({
  post,
  displayIcons = false,
  type,
  className,
}: Props) => {
  const { showViewCount, showCommentCount, showLikeCount } =
    useFeedMetadataSettings({ post });
  const { textColorClassName } = useLayoutColorClasses();
  const { contentFontClassName } = useFontClassName();

  return (
    <div
      data-hook="post-footer"
      className={classNames(
        styles.container,
        type ? styles[type] : undefined,
        contentFontClassName,
        textColorClassName,
        'post-footer',
        className,
      )}
    >
      {(showViewCount || showCommentCount) && (
        <div className={styles.leftButtons}>
          <PostStats
            postId={resolveId(post)!}
            postCommentsDisabled={post.isCommentsDisabled}
            displayIcons={displayIcons}
            showViewCount={showViewCount}
            showCommentCount={showCommentCount}
          />
        </div>
      )}
      {showLikeCount && (
        <div className={styles.rightButtons}>
          <LikeButtonWithCount
            postId={resolveId(post)!}
            className="post-footer__like-button"
          />
        </div>
      )}
    </div>
  );
};
