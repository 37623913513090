import { useMemo } from 'react';
import {
  getLayoutName,
  type LayoutType,
  type LayoutTypeName,
  type MobileLayoutType,
  type MobileLayoutTypeName,
  POST_LIST_COMPONENT_MASONRY,
  type Section,
  SEO_LAYOUT_TYPE_FALLBACK,
  type Layout,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import {
  getLayoutType,
  isMasonryLayoutSupported as getIsMasonryLayoutSupported,
} from '../selectors/layout-selectors';
import { getSection } from '../selectors/section-selectors';
import { getLayoutConfig } from '../services/layout-config';

export type UseLayoutPropsParams = {
  fallbackEnabled?: boolean;
  section?: Section;
  layoutType?: Layout | LayoutType;
  mobileLayoutType?: MobileLayoutType;
};

export type UseLayoutProps = {
  section?: Section | undefined;
  layoutType?: Layout | LayoutType | undefined;
  mobileLayoutType?: MobileLayoutType;
  layoutName: LayoutTypeName;
  mobileLayoutName: MobileLayoutTypeName;
};

export default function useLayoutProps(
  params: UseLayoutPropsParams = {},
): UseLayoutProps {
  const section = useSelector(
    (state) =>
      params.section ?? getSection(state, params.fallbackEnabled ?? true),
  );
  const mobileLayoutType = useSelector(
    (state) =>
      params.mobileLayoutType ??
      (getLayoutType(state, section, true) as MobileLayoutType),
  );

  const layoutType = useSelector(
    (state) =>
      params.layoutType ?? (getLayoutType(state, section) as LayoutType),
  );

  const isMasonryLayoutSupported = useSelector(getIsMasonryLayoutSupported);

  const memoizedLayoutProps = useMemo(() => {
    if (
      !isMasonryLayoutSupported &&
      getLayoutConfig(layoutType)?.listComponentName ===
        POST_LIST_COMPONENT_MASONRY
    ) {
      return {
        section,
        layoutType: SEO_LAYOUT_TYPE_FALLBACK as LayoutType,
        mobileLayoutType,
        layoutName: getLayoutName(SEO_LAYOUT_TYPE_FALLBACK),
        mobileLayoutName: getLayoutName(
          mobileLayoutType,
        ) as MobileLayoutTypeName,
      };
    }

    return {
      section,
      layoutType,
      mobileLayoutType,
      layoutName: getLayoutName(layoutType),
      mobileLayoutName: getLayoutName(mobileLayoutType) as MobileLayoutTypeName,
    };
  }, [section, layoutType, mobileLayoutType, isMasonryLayoutSupported]);

  return memoizedLayoutProps;
}
