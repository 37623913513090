import React from 'react';
import classNames from 'classnames';
import type { GalleryProps } from 'pro-gallery-lib';
import {
  isLayoutList,
  resolveId,
  type Layout,
  type LayoutName,
  type PostAction,
  type Section,
} from '@wix/communities-blog-client-common';
import type {
  TextBoxAlignment,
  TextPlacement,
} from '../../constants/pro-gallery-options';
import {
  getLayoutConfig,
  type LayoutConfig,
} from '../../services/layout-config';
import type { NormalizedPost } from '../../types';
import PostListItemProGallery from '../post-list-item-pro-gallery';
import styles from './create-info-renderer.scss';

type CreateInfoRendererProps = {
  allPosts: NormalizedPost[];
  prevAllPosts: NormalizedPost[];
  layoutType?: Layout;
  layoutName?: LayoutName;
  section: Section;
  layoutSidesPadding: number;
  canSeeMoreButton: (post: NormalizedPost) => boolean;
  textBoxAlignment: TextBoxAlignment;
  options: NonNullable<GalleryProps['options']>;
  getPostClassName: (...classNames: string[]) => string[];
  visibleActions?: PostAction[];
};

export const createInfoRenderer =
  ({
    allPosts,
    prevAllPosts,
    layoutType,
    layoutName,
    section,
    layoutSidesPadding,
    canSeeMoreButton,
    textBoxAlignment,
    options,
    getPostClassName,
    visibleActions,
  }: CreateInfoRendererProps) =>
  (itemProps: Pick<NormalizedPost, 'id'>, placement: TextPlacement) => {
    if (isLayoutList(layoutType) && placement === 'SHOW_BELOW') {
      const containerClassName = classNames(
        getPostClassName('background-color'),
        styles.listLayoutFullHeightBackground,
      );
      return <div className={containerClassName}></div>;
    }

    const layoutConfig: Partial<LayoutConfig> = layoutType
      ? getLayoutConfig(layoutType)
      : {};
    const currentPost =
      allPosts.find((post) => resolveId(post) === itemProps.id) ||
      prevAllPosts.find((post) => resolveId(post) === itemProps.id);

    return (
      <PostListItemProGallery
        itemConfig={layoutConfig.itemConfig ?? {}}
        type={layoutName}
        postId={itemProps.id}
        post={currentPost}
        galleryOptions={options}
        layoutSidesPadding={layoutSidesPadding}
        section={section}
        canSeeMoreButton={canSeeMoreButton}
        textBoxAlignment={textBoxAlignment}
        visibleActions={visibleActions}
      />
    );
  };
